import React from 'react';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { useClassnames } from '../../../../hooks/use-classnames';
import Arrow from '../../../BlogPage/components/BlogArticles/assets/arrow.inline.svg';
import GridWrapper from '../../../grid-wrapper';

import './index.css';


const rootClassName = 'news-slider';

const swiperSetting = {
    navigation: {
        prevEl: `.${rootClassName}__navigation-prev`,
        nextEl: `.${rootClassName}__navigation-next`
    },
    loop: true
};

interface IProps {
    items: Array<{ url: string }>
}

const NavigationButton = () => {
    const cn = useClassnames();
    return (
        <div className={cn(`${rootClassName}__navigation`)}>
            <button className={cn(`${rootClassName}__navigation-prev ${rootClassName}__navigation-btn`)}>
                <Arrow />
            </button>
            <button className={cn(`${rootClassName}__navigation-next ${rootClassName}__navigation-btn`)}>
                <Arrow />
            </button>
        </div>
    );
};

const Item = ({ url }: { url: string }) => {
    return (
        <div className={`${rootClassName}__slide-item`}>
            <picture>
                <img src={url} alt="slide-img" width="100%" height="100%" />
            </picture>
        </div>
    );
};

const Slider = ({ items }: IProps) => {
    const cn = useClassnames();
    return (
        <GridWrapper className={cn(`${rootClassName}`)}>
            <Swiper className={`${rootClassName}__carousel`} slidesPerView="1" centeredSlides={false} spaceBetween={0} modules={[Navigation]} {...swiperSetting}>
                {items.map((item, index) => (
                    <SwiperSlide key={index} className={`${rootClassName}__slide`}>
                        <Item {...item} />
                    </SwiperSlide>
                ))}
            </Swiper>
            <NavigationButton />
        </GridWrapper>
    );
};

export default Slider;
